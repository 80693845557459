<script>
import { EventBus } from "@/shared/util/event-bus.js";
export default {
  props: ["rowIndex", "prop", "model"],
  name: "isExcludedTemplte",
  data() {
    return {};
  },
  computed: {
    isIncluded: {
      get() {
        //your validation
        return this.model[this.prop] ?? true;
      },
      set(val) {
        this.model[this.prop] = val;
      }
    }
  },
  methods: {
    changeStatus() {
      if (!this.isIncluded) EventBus.$emit("manuallyExcluded", this.model);
      else EventBus.$emit("manuallyIncluded", this.model);
    }
  }
};
</script>

<template>
  <div>
    <b-form-checkbox
      v-model="isIncluded"
      name="check-button"
      switch
      class="mt-2"
      @change="changeStatus()"
    >
    </b-form-checkbox>
  </div>
</template>
