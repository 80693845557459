<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import RespondentModal from "@/redem/datafiles/components/modals/respondent-modal/index.vue";
import { EventBus } from "@/shared/util/event-bus.js";
export default {
  props: {
    rowIndex: Number,
    prop: String,
    model: Object,
    recordIdColumnName: String
  },
  name: "infoTemplte",
  components: {
    RespondentModal
  },
  data() {
    return {
      /****** Operational Varibales *****/
      showRespondentModel: false
    };
  },
  methods: {
    clickOnInfo() {
      EventBus.$emit("clickOnInfo", { recordId: this.model[this.recordIdColumnName] });
    }
  }
};
</script>

<template>
  <div>
    <i
      class="bx bxs-info-circle text-info"
      style="cursor:pointer"
      @click="clickOnInfo()"
    ></i>
  </div>
</template>
