<script>
import WorksheetService from "@/api/services/worksheet.service";

export default {
  props: {
    editedCells: Array,
    visible: Boolean,
    closeable: Boolean,
    headerRow: Object,
    worksheetRows: Object,
    datafile: String,
    settings: Object
  },
  data() {
    return {
      /******** Data Variables ******/
      columns: null,
      rows: null,
      datafileId: null,
      /******** Operational Variables *******/
      isLoading: false,
      isSuccess: false,
      isError: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  mounted() {
    if (this.headerRow && this.worksheetRows && this.datafile) {
      this.columns = this.headerRow;
      this.rows = this.worksheetRows;
      this.datafileId = this.datafile;
    }
  },
  methods: {
    async clickSave() {
      this.isLoading = true;
      //console.log("headerRows: ", this.columns);
      //console.log("Rows: ", this.rows);
      const firstRow = this.rows[0];
      const secondRow = this.rows[1];
      console.log(firstRow["ID"], secondRow["ID"]);
      console.log(this.editedCells);
      let response = await WorksheetService.updateWorksheet(
        this.datafileId,
        this.editedCells,
        this.rows,
        this.columns,
        this.settings
      );

      if (response.status) {
        this.isLoading = false;
        this.isSuccess = true;
        this.$emit("reload");
      } else {
        this.isLoading = false;
        this.isError = true;
      }
    },
    clickCancel() {
      this.$emit("close");
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="p-2">
      <!-- Form -->
      <div v-if="!isLoading && !isSuccess">
        <!-- Header -->
        <div class="row mt-2 text-center">
          <div class="col-12">
            <h5>{{ $t("worksheet.saveSettingsModal.title") }}</h5>
            <p class="mt-3">
              {{ $t("worksheet.saveSettingsModal.description") }}
            </p>
          </div>
        </div>

        <!-- Error Alert -->
        <div class="row">
          <div class="col-12">
            <b-alert
              v-model="isError"
              class="mt-3"
              variant="danger"
              dismissible
              >{{ $t("worksheet.saveSettingsModal.errorAlert") }}</b-alert
            >
          </div>
        </div>

        <!-- Actions -->
        <div class="row mt-3 text-center">
          <div class="col-12">
            <b-button @click="clickSave()" variant="success" class="mr-2"
              ><i class="bx bx-save"></i>
              {{ $t("worksheet.saveSettingsModal.saveButton") }}</b-button
            >
            <b-button @click="clickCancel()" variant="light"
              ><i class="bx bx-x"></i>
              {{ $t("worksheet.saveSettingsModal.cancelButton") }}</b-button
            >
          </div>
        </div>
      </div>

      <!-- Loading -->
      <div v-if="isLoading && !isSuccess">
        <div class="row">
          <div class="col-12 text-center">
            <b-spinner
              style="width: 5rem; height: 5rem"
              class="m-2"
              variant="mid"
              role="status"
            ></b-spinner>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-3">
            <h5 class="text-cess">
              {{ $t("worksheet.saveSettingsModal.loading.title") }}
            </h5>
            {{ $t("worksheet.saveSettingsModal.loading.description") }}
          </div>
        </div>
      </div>

      <!-- Success -->
      <div v-if="!isLoading && isSuccess">
        <div class="row">
          <div class="col-12 text-center">
            <i class="bx bx-check-circle bx-tada text-success display-1"></i>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-3">
            <h5 class="text-cess">
              {{ $t("worksheet.saveSettingsModal.success.title") }}
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4 text-center">
            <b-button @click="clickCancel()" variant="light"
              ><i class="bx bx-x"></i>
              {{
                $t("worksheet.saveSettingsModal.success.continueButton")
              }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
