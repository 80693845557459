<script>
import DatarecordService from "@/api/services/datarecord.service.js";
export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    datafileId: String,
    respondentsToDelete: Array
  },
  data() {
    return {
      numberOfRespondentsToDelete: 0,
      /****** Operational Variables ******/
      isLoading: false,
      isSuccess: false,
      isError: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  mounted() {
    this.numberOfRespondentsToDelete = this.respondentsToDelete.length;
  },
  methods: {
    clickCancelButton() {
      this.$emit("close");
    },
    async clickDeleteButton() {
      this.isLoading = true;
      try {
        const response = await DatarecordService.deleteRecords(this.datafileId, this.respondentsToDelete);
        if (response) {
          this.isLoading = false;
          this.isSuccess = true;
        }
      } catch (e) {
        this.isLoading = false;
        this.isError = true;
        console.log(e);
      }
    },
    clickCloseButton() {
      this.$emit("reload");
    }
  }
};
</script>

<template>
  <!-- Delete Dataset Model -->
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <!-- Error Message -->
    <div class="text-center" v-if="!isLoading && isError">
      <div class="col-md-12">
        <b-alert class="mt-3" variant="danger" v-model="isError"
          >{{ $t("worksheet.deleteRespondentsModal.errorAlert") }}
        </b-alert>
      </div>
    </div>

    <!-- Form -->
    <div class="mt-3 mb-3 text-center" v-if="!isLoading && !isSuccess">
      <div class="col-md-12 mb-2">
        <img
          src="@/assets/images/delete-datafile.png"
          alt
          class="img-fluid mb-4 mt-3"
          style="width: 80px"
        />
        <h5>{{ $t("worksheet.deleteRespondentsModal.title") }}</h5>
      </div>
      <div class="col-md-12 mt-3">
        <p>
          <label>
            {{ numberOfRespondentsToDelete }} {{ $t("worksheet.deleteRespondentsModal.InfoMessage.part1") }}
          </label>
          <br />
          {{ $t("worksheet.deleteRespondentsModal.description.part1") }}
          <br />
          {{ $t("worksheet.deleteRespondentsModal.description.part2") }}
        </p>
      </div>
      <div class="col-md-12 mt-4">
        <b-button variant="danger" @click="clickDeleteButton()">{{
          $t("worksheet.deleteRespondentsModal.buttons.delete")
        }}</b-button>
        <b-button variant="light" class="ml-2" @click="clickCancelButton()">{{
          $t("worksheet.deleteRespondentsModal.buttons.cancel")
        }}</b-button>
      </div>
    </div>

    <!-- Success Message -->
    <div class="mt-3 mb-3 text-center" v-if="!isLoading && isSuccess">
      <div class="col-md-12 mb-2">
        <i class="bx bx-check-circle bx-tada text-success display-1"></i>
        <h5 class="mt-3">
          {{ numberOfRespondentsToDelete }} {{ $t("worksheet.deleteRespondentsModal.successMessage.part1") }}
        </h5>
      </div>
      <div class="col-md-12 mt-4">
        <b-button variant="light" class="ml-2" @click="clickCloseButton()">{{
          $t("worksheet.deleteRespondentsModal.buttons.close")
        }}</b-button>
      </div>
    </div>

    <!-- Form -->
    <div class="row justify-content-center" v-if="isLoading">
      <div class="col-6 text-center pt-5 pb-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </b-modal>
</template>
