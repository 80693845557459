import CustomErrorMessage from "@/shared/util/customErrorMessage.js";
import { ApiCall } from "../apiBase";

class DatarecordService {
  async deleteRecords(
    datafileId,
    recordIds
  ) {
    recordIds = recordIds.map((recordId) => String(recordId));
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
        mutation deleteRecords($datafileId: ID!, $recordIds: [String]) {
          deleteRecords(datafileId: $datafileId, recordIds: $recordIds) {
            status
            message
          }
        }
        `,
        variables: {
          datafileId,
          recordIds
        }
      }
    });

    if (result?.data?.data?.deleteRecords && !result?.data?.errors) {
      return result.data.data.deleteRecords;
    } else {
      throw new CustomErrorMessage(
        result?.data?.errors[0]?.extensions?.code,
        result?.data?.errors[0].message
      );
    }
  }
}

export default new DatarecordService();