<script>
export default {
  props: ["rowIndex", "prop", "model"],
  name: "categoryTemplate",
  data() {
    return {};
  },
  methods: {}
};
</script>
<template>
  <div>
    <span v-if="!model[prop]"> - </span>
    <span
      v-else
      class="badge badge-silver font-size-12"
      :class="{
        'oes-answer-cat-va': `${model[prop]}` == `Valid Answer`,
        'oes-answer-cat-ga': `${model[prop]}` == `Generic Answer`,
        'oes-answer-cat-ni': `${model[prop]}` == `No Information`,
        'oes-answer-cat-pr': `${model[prop]}` == `Bad Language`,
        'oes-answer-cat-no': `${model[prop]}` == `Nonsense`,
        'oes-answer-cat-dr': `${model[prop]}` == `Duplicate Respondent`,
        'oes-answer-cat-da': `${model[prop]}` == `Duplicate Answer`,
        'oes-answer-cat-wt': `${model[prop]}` == `Wrong Topic`,
        'oes-answer-cat-wl': `${model[prop]}` == `Wrong Language`,
        'oes-answer-cat-cp': `${model[prop]}` == `Copy Paste`,
        'oes-answer-cat-ar': `${model[prop]}` == `Fake Answer`,
        'oes-answer-cat-ai-error': `${model[prop]}` == `AI Error`
      }"
    >
      {{ model[prop] }}
    </span>
  </div>
</template>
