<script>
import simplebar from "simplebar-vue";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    allColumns: Array,
    isLiveCleaning: Boolean
  },
  components: {
    simplebar
  },
  data() {
    return {
      /******** Data Variables ******/
      showAllRedemColumns: false,
      showAllOtherColumns: false,
      showAllSelectedColumns: false,
      columns: [],
      otherColumns: [],
      redemColumns: []
      /******** Operational Variables *******/
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  mounted() {
    if (this.allColumns) {
      this.seperateColumns(this.allColumns);
    }
  },
  methods: {
    seperateColumns(columns) {
      this.redemColumns = [];
      this.otherColumns = [];

      for (const column of columns) {
        if (column.name.includes("Language:OES")) continue; // Hide the language column for now
        if (column.name !== "Include" && column.name !== "Mark") {
          if (column.isAddedByRedem) {
            this.redemColumns.push(column);
          } else {
            this.otherColumns.push(column);
          }
        }
      }

      this.columns = [...this.redemColumns, ...this.otherColumns];

      // set values to Show All Redem Column Checkbox
      let hideRedemColumnCount = 0
      for(let i=0; i < this.redemColumns.length; i++) {  
        if(!this.redemColumns[i].hide) hideRedemColumnCount++
      }
      if(hideRedemColumnCount===this.redemColumns.length) this.showAllRedemColumns= true

      // set values to Show All Other Column Checkbox
      let hideOtherColumnCount = 0
      for(let i=0; i < this.otherColumns.length; i++) {  
        if(!this.otherColumns[i].hide) hideOtherColumnCount++
      }
      if(hideOtherColumnCount===this.otherColumns.length){
        this.showAllOtherColumns= true
        this.showAllSelectedColumns = true
      }

      // set value to show all selected column checkbox
      let hideSelectedColumnCount = 0
      let selectedColumnCounnt = 0
      for(let i=0; i < this.otherColumns.length; i++) {  
        if(this.otherColumns[i].readonly) selectedColumnCounnt++
        if(this.otherColumns[i].readonly && !this.otherColumns[i].hide) hideSelectedColumnCount++
      }
      if(hideSelectedColumnCount===selectedColumnCounnt) this.showAllSelectedColumns = true
    },
    searchWithKey(key) {
      //write search logic only if there are atleast 2 keys
      if (key.length > 0) {
        const tempColumns = this.allColumns.filter(({ name }) => {
          const valueFromData = name.toUpperCase();
          const valueFromUser = key.toUpperCase();

          return valueFromData.includes(valueFromUser);
        });
        this.seperateColumns(tempColumns);
      } else {
        this.seperateColumns(this.allColumns);
      }
    },
    isDefaultHiddenColumn(columnName) {
      if (
        columnName === "R" ||
        columnName === "R-Weight" ||
        columnName === "Include" ||
        columnName === "Mark"
      )
        return true;
      else return false;
    },
    closeMode() {
      this.$emit("close");
    },
    clickSave() {
      //rest the original results on modal action
      this.columns = this.allColumns;
      this.$emit("getColumns", this.columns);
    },
    clickCancel() {
      //rest the original results on modal action
      this.columns = this.allColumns;
      this.$emit("close");
    },
    selectAllRedemColumns(newValue) {
      if(newValue) {
        this.redemColumns.forEach(column => {
          column.hide = false
        });
      } else {
        this.redemColumns.forEach(column => {
          if(column.name !== 'R-Score') column.hide = true
        });
      }
    },
    changeHideValueOfRedemColumn(columnIndex) {
      if(this.redemColumns[columnIndex].hide) {
        this.showAllRedemColumns= false
      } else {
        let hideColumnCount = 0
        for(let i=0; i < this.redemColumns.length; i++) {  
          if(!this.redemColumns[i].hide) hideColumnCount++
        }

        if(hideColumnCount===this.redemColumns.length) this.showAllRedemColumns= true
      }
    },
    selectAllOtherColumns(newValue) {
      if(newValue) {
        this.otherColumns.forEach(column => {
          column.hide = false
        });
        this.showAllSelectedColumns = true
      } else {
        this.otherColumns.forEach(column => {
          column.hide = true
        });
        this.showAllSelectedColumns = false
      }
    },
    selectAllSelectedColumns(newValue){
      if(newValue) {
        this.otherColumns.forEach(column => {
          if(column.readonly) column.hide = false
        });

        let hideColumnCount = 0
        for(let i=0; i < this.otherColumns.length; i++) {  
          if(!this.otherColumns[i].hide) hideColumnCount++
        }

        if(hideColumnCount===this.otherColumns.length) this.showAllOtherColumns = true
      } else {
        this.otherColumns.forEach(column => {
          if(column.readonly) column.hide = true
        });
        this.showAllOtherColumns = false
      }
    },
    changeHideValueOfOtherColumn(columnIndex) {
      if(this.otherColumns[columnIndex].hide) {
        this.showAllOtherColumns= false

        if(this.otherColumns[columnIndex].readonly) this.showAllSelectedColumns = false
      } else {
        let hideColumnCount = 0
        for(let i=0; i < this.otherColumns.length; i++) {  
          if(!this.otherColumns[i].hide) hideColumnCount++
        }

        if(hideColumnCount===this.otherColumns.length)  {
          this.showAllOtherColumns= true
          this.showAllSelectedColumns = true
        }
      }
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    scrollable
    hide-footer
    hide-header
    no-close-on-backdrop
    size="xl"
  >
    <div class="p-2">
      <!-- title -->
      <div class="row mt-2">
        <div class="col-md-12">
          <h5>
            <i class="bx bx-wrench"></i>
            {{ $t("worksheet.manageColumnModal.title") }}
          </h5>
          <p class="mt-2">
            {{ $t("worksheet.manageColumnModal.text") }}
          </p>
        </div>
      </div>

      <!-- Search bar -->
      <div class="row align-items-center mb-4 mt-3">
        <div class="col-md-1">
          <label>
            {{ $t("worksheet.manageColumnModal.search") }}
          </label>
        </div>
        <div class="col-md-6">
          <b-input
            @input="this.searchWithKey"
            type="search"
            :placeholder="
              this.$i18n.t('worksheet.manageColumnModal.searchPlaceholder')
            "
            class="form-control"
          ></b-input>
        </div>
      </div>

      <!-- columns -->
      <div class="row">
        <!-- ReDem Columns -->
        <div class="col-md-6">
          <div style="border: 1px solid #d9d9d9;height: 400px">
            <div class="card-body">
              <label class="d-inline-flex align-items-center">
                {{ $t("worksheet.manageColumnModal.category1") }}
              </label>

              <!-- Manage Options -->
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="showAllRedemColumns"
                    @change="selectAllRedemColumns"
                  >
                    {{ $t("worksheet.manageColumnModal.showAllRedemColumns") }}
                  </b-form-checkbox>
                </div>
              </div>

              <hr/>

              <!-- Column Options -->
              <div class="row">
                <div class="col-md-12">
                  <simplebar style="max-height: 280px">
                    <div
                      class="mb-3"
                      v-for="(redemColumn, columnIndex) in this.redemColumns"
                      :key="columnIndex"
                    >
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          :id="columnIndex"
                          v-model="redemColumn.hide"
                          :true-value="false"
                          :disabled="isDefaultHiddenColumn(redemColumn.prop)"
                          :false-value="true"
                          @change="changeHideValueOfRedemColumn(columnIndex)"
                        />
                        <label class="custom-control-label" :for="columnIndex">
                          <span class="font-weight-normal">
                            {{ redemColumn.name }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </simplebar>
                </div>
              </div>
              
            </div>
          </div>
        </div>

        <!-- Other Columns -->
        <div class="col-md-6">
          <div style="border: 1px solid #d9d9d9; height: 400px">
            <div class="card-body">
              <label class="d-inline-flex align-items-center">
                {{ $t("worksheet.manageColumnModal.category2") }}
              </label>

              <!-- Manage Options -->
              <div class="row">
                <div class="col-md-4">
                  <b-form-checkbox
                    v-model="showAllOtherColumns"
                    @change="selectAllOtherColumns"
                  >
                    {{ $t("worksheet.manageColumnModal.showAllOtherColumns") }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-8">
                  <b-form-checkbox
                    v-if="!isLiveCleaning"
                    v-model="showAllSelectedColumns"
                    @change="selectAllSelectedColumns"
                    :disabled="showAllOtherColumns"
                  >
                    {{ $t("worksheet.manageColumnModal.showAllSelectedColumns") }}
                  </b-form-checkbox>
                </div>
              </div>

              <hr/>

              <!-- Column Options -->
              <div class="row">
                <div class="col-md-12">
                  <simplebar style="max-height: 280px; overflow-x: hidden;">
                    <div
                      class="row mb-3"
                      v-for="(otherColumn, columnIndex) in this.otherColumns"
                      :key="otherColumn.name + columnIndex"
                    >
                      <div class="col-md-7">
                        <b-form-checkbox
                          v-model="otherColumn.hide"
                          :value="false"
                          :disabled="isDefaultHiddenColumn(otherColumn.prop)"
                          :unchecked-value="true"
                          @change="changeHideValueOfOtherColumn(columnIndex)"
                        >
                          {{ otherColumn.name }}
                        </b-form-checkbox>
                      </div>
                      <div class="col-md-5">
                        <b-badge variant="info" v-if="otherColumn.readonly">
                          {{ $t("worksheet.manageColumnModal.selectedColumnLabel") }}
                        </b-badge>
                      </div>
                    </div>
                  </simplebar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Button Row -->
      <div class="row mt-4 mb-2">
        <div class="col-md-12">
          <b-button @click="clickSave()" variant="success">
            <i class="bx bx-save"></i>
            {{ $t("worksheet.manageColumnModal.saveButton") }}</b-button
          >
          <b-button @click="clickCancel()" variant="light" class="ml-2">
            <i class="bx bx-x"></i>
            {{ $t("worksheet.manageColumnModal.cancelButton") }}</b-button
          >
        </div>
      </div>

    </div>
  </b-modal>
</template>

<style scoped>
</style>
